import React from "react";

import SidebarComponent from "../../SidebarComponent/SidebarComponent";
import IconDetail from "../../IconDetail/IconDetail";
import DetailList from "../../DetailList/DetailList";

import { ReactComponent as Twitter } from "../../../svg/social-logos/twitter.svg";
import { ReactComponent as LinkedIn } from "../../../svg/social-logos/linkedin.svg";

import "./SocialShare.scss";

const SocialShare = ({ publicUrl }) => {
    return (
        <SidebarComponent title="Share">
            <DetailList vertical>
                <IconDetail
                    link={`https://twitter.com/intent/tweet?url=${encodeURI(publicUrl)}`}
                    icon={<Twitter/>}>
                    Tweet this article
                </IconDetail>

                <IconDetail
                    link={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(publicUrl)}`}
                    icon={<LinkedIn/>}>
                    Share on LinkedIn
                </IconDetail>
            </DetailList>
        </SidebarComponent>
    );
};

export default SocialShare;
