import React from "react";
import { graphql } from "gatsby";

import Post from "../sections/Post/Post";
import SocialShare from "../components/sidebar/SocialShare/SocialShare";

export default function PageTemplate({ data: { mdx, site }, pageContext }) {
  const { postPath, previous, next } = pageContext;
  const publicUrl = `${site.siteMetadata.siteUrl}${postPath}`;

  return (
    <>
      <Post
        post={mdx}
        publicUrl={publicUrl}
        sidebarComponents={[<SocialShare publicUrl={publicUrl} />]}
      />
    </>
  );
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    site {
      siteMetadata {
        disqusShortname
        siteUrl
      }
    }
    mdx(id: { eq: $id }) {
      fields {
        slug
        path
      }
      tableOfContents
      timeToRead
      frontmatter {
        draft
        title
        description
        categories
        date(formatString: "MMMM DD, YYYY")
        toc
        featuredImage {
          seoImage: childImageSharp {
            fixed(width: 1200, height: 630) {
              src
              srcWebp
            }
          }

          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      body
    }
  }
`;
